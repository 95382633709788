// @ts-check

/**
 * @typedef {{ id: string, session: string, isLocal: boolean, isWs: boolean, number: number, unread: boolean, pageinfo: { name: string, url: string, device: 'L' | 'M' | 'S' } }} User
 * @typedef {{avatar: string, message: string, self: boolean, senderName: string, shownName: string, time: Date, file?: { filename?: string, originalName?: string }}} Message
 * @typedef {{[userId: string]: { name: string, messages: Array<Message> }}} MessagesDictionary
 */

import 'admin-lte/bootstrap/js/bootstrap';
import 'admin-lte/plugins/slimScroll/jquery.slimscroll';
import 'admin-lte/plugins/fastclick/fastclick';
import 'admin-lte/dist/js/app';
import { EmojiConvertor } from 'emoji-js';
import io from 'socket.io-client';
import habitat from 'preact-habitat';
import { StatusSelect } from './components/status-select';
import { AVAILABILITY } from '../../enums/Availability';
import { startHealthcheck } from './healthcheck';
import {
  showMessages,
  sendMessage,
  getContent,
  updateUserList,
  updateAdminList,
  changeFavicon,
  doBeep,
  updateTitle,
  uploadFile,
  appendFileToMessage,
  uploadFileButtonFunc,
  requestAvailability,
  updateAvailability,
  destroyAvailability,
  showNotification,
  isAdmin,
  disableForm,
  enableForm,
  requestVideoChat
} from './client_functions';
import { saveMessage } from '../../../shared/js/save-message';
import { validateEmail, supportsJitsi } from '../../../shared/js/utils';
import Cookies from 'universal-cookie';
import { Modal } from './modal';
import { emailChatHistory } from './email-chat-history';
import { getNewVideo } from '../../../shared/js/videochat';
import Features from '../../../config/features';
import tippy from 'tippy.js';

(function($) {
  $(document).ready(function() {
    let sessionExpiredModal;
    let sessionExpired = false;
    let session;
    let pingTimeouts = 0;
    const SUPPORTS_JITSI = supportsJitsi();

    startHealthcheck({
      pingCallback: (params) => {
        /**
         * this check means we are not fully connected yet, because
         * the session gets set in the `onLogin` callback
         */
        if (session === undefined) {
          return;
        }

        /**
         * in case the pingCallback didn't return anything,
         * we can assume that we don't have a valid session
         */
        if (!params) {
          onSessionExpire(10001);
          return;
        }

        /**
         * we receive params, but it contains a 'reasonForFailure'
         */
        if (params.reasonForFailure) {
          if (
            params.reasonForFailure.message &&
            params.reasonForFailure.message === 'Request timed out'
          ) {
            // the failure was caused by a request that timed out
            if (++pingTimeouts === 2) {
              onSessionExpire(10002);
            }
            return;
          }
        }
        pingTimeouts = 0;

        /**
         * if either the session that was returned by the server is not defined,
         * or doesn't match our current session, we can also assume that we don't have a valid session
         */
        if (!params.session || params.session !== session) {
          onSessionExpire(10003);
          return;
        }

        /**
         * in this case, the session still valid,
         * so we can remove the "Session-expired" modal if we've shown it
         */
        if (sessionExpiredModal && sessionExpired) {
          sessionExpiredModal.destroy();
          sessionExpiredModal = undefined;
          sessionExpired = false;
        }
      }
    });

    // @ts-ignore
    if (!window.__STATE) {
      return;
    }
    const cookies = new Cookies();

    // @ts-ignore
    var socketServer = window.__STATE.socket_server;
    var originalPageTitle = document.title;

    // Define variables for loading content
    var login = '/login.html';
    var chat = '/chat.html';
    var front = $('.front');
    var back = $('.back');

    // User only connects to socket server when logged in
    var onLogin = function(_session) {
      session = _session;

      var socket = io.connect(socketServer);
      socket.on('connect', function() {
        if (sessionExpired) {
          socket.disconnect();
          return;
        }
        onConnect(session, socket);
      });
    };

    var onConnect = function(session, socket) {
      console.log('connected to socket');
      // Initializing settings and variables
      let currentDiscussionId;
      var strSendToName = '';
      var myGuestNumber = null;
      var strShownName = null;
      var strMyId = '';
      /** @type {MessagesDictionary} */
      var objMessages = {};
      var arrAdmins = [];
      /**
       * @type {Array<User>}
       */
      var arrUsers = [];
      var intUnreadMessages = 0;
      var objUnreadMessages = {};
      var intConnectedUsers = 0;
      /**
       * The session id of the user that the admin is currently talking to
       * @type {string}
       */
      var currentChat = '';
      var objUserTalkingTo = {};
      var windowFocus;
      var typing = false;
      var timeout = undefined;
      let availability = AVAILABILITY.BUSY;
      var objConstants = {
        strGuestPrefix: 'Gast',
        strAdminPrefix: 'Admin'
      };
      var emoji = new EmojiConvertor();
      // @ts-ignore
      emoji.init_env(); // else auto-detection will trigger when we first convert
      // @ts-ignore
      emoji.replace_mode = 'unified';
      // @ts-ignore
      emoji.allow_native = true;

      /** @type {HTMLElement} */
      const app = document.querySelector('.ws-chat-app');

      /** @type {HTMLButtonElement} */
      const endChatButton = document.querySelector('.end-chat');

      /** @type {HTMLButtonElement} */
      const mailHistoryButton = document.querySelector('.mail-history');

      function adjustAdminToolsVisibility() {
        const messagesExist = objMessages[currentChat] !== undefined;
        const lockedToMe = objUserTalkingTo[currentChat] === strMyId;
        const isUser = messagesExist && !isAdmin(objMessages[currentChat].name);

        endChatButton.classList.toggle('visible', messagesExist && lockedToMe && isUser);
        mailHistoryButton.classList.toggle(
          'visible',
          messagesExist && lockedToMe && isUser
        );
      }

      function updateUsers() {
        updateUserList({
          users: arrUsers.filter(({ id }) => {
            return objUserTalkingTo[id] !== strMyId;
          }).sort(({id}, _) => {
            return objUserTalkingTo[id] !== undefined ? 1 : -1;
          }),
          ownSocketId: strMyId,
          objConstants: objConstants,
          objUserTalkingTo: objUserTalkingTo,
          availability: availability,
          onUserSelect: onUserClick,
          selectedChat: currentChat,
          selector: '.chat-tabs-left',
          numberSelector: '.number-users',
          noEntriesMessage: arrUsers.length
            ? 'Momentan befinden sich keine weiteren Benutzer auf der Website'
            : 'Momentan befindet sich kein Benutzer auf der Website'
        });
      }

      function updatePartners() {
        updateUserList({
          users: arrUsers.filter(({ id }) => {
            return objUserTalkingTo[id] === strMyId;
          }),
          ownSocketId: strMyId,
          objConstants: objConstants,
          objUserTalkingTo: objUserTalkingTo,
          availability: availability,
          onUserSelect: onUserClick,
          selectedChat: currentChat,
          selector: '.partner-tabs-left',
          numberSelector: '.number-partner',
          noEntriesMessage: 'Momentan ist Ihnen kein Benutzer zugeteilt'
        });
      }

      function unselectChatPartner() {
        app.style.display = 'none';
        currentChat = '';

        updateUsers();
        updatePartners();
      }

      /**
       * @param {string} partner
       */
      function selectChatPartner(partner) {
        app.style.display = 'block';
        currentChat = partner;

        document.querySelector('.back').classList.remove('inactive-chat-partner');
        enableForm();
        updateUsers();
        updatePartners();

        currentDiscussionId = undefined;
      }

      function onStatusChange(newStatus) {
        availability = newStatus.value;
        socket.emit('status:set', {
          session: session,
          availability: availability
        });
      }

      window.addEventListener('beforeunload', beforeUnload);

      endChatButton.addEventListener('click', function(e) {
        e.preventDefault();

        socket.emit('discussion:unassign', { session: session, client: currentChat });

        var objInputEl = $('.chatForm input[name="message"]');
        var objSubmitBtn = $('.chatForm .submitButton');
        objInputEl.prop('disabled', true);
        objSubmitBtn.prop('disabled', true);

        var objBoxTitle = $('.ws-chat-app .box-header .box-title');
        objBoxTitle.text('Bitte Seitenbesucher wählen');
        var objMessagesDiv = $('.direct-chat-messages');
        objMessagesDiv.html('');
        objMessagesDiv.append(
          `
            <div style="text-align: center; padding: 50px; font-size: 18px;">
              <p style="color:red;">Der Chat wurde beendet.</p>
              Bitte wählen Sie linksseitig aus dem Bereich "Seitenbesucher" einen neuen Chat-Partner aus.
            </div>
          `
        );
        unselectChatPartner();
        adjustAdminToolsVisibility();
      });

      mailHistoryButton.addEventListener('click', function(e) {
        mailHistoryButton.disabled = true;

        const modal = new Modal({
          title: 'Chatverlauf senden',
          content: `
            Geben Sie bitte eine E-Mail Adresse an, an die der Chatverlauf gesendet werden soll. Außerdem haben Sie die Möglichkeit, den Verlauf um einen Kommentar zu ergänzen.<br /><br />

            <form id="email-history-form" onsubmit="event.preventDefault(); return false;">
              <label>
                E-Mail Adresse<br />
                <input name="email" type="email" />
              </label>
              <br />
              <label style="display: block;">
                Kommentar (optional)<br />
                <textarea name="comment" style="width: 100%;" rows="4"></textarea>
              </label>
              <br />
              <span class="errors"><span>
            </form>
          `,
          options: {
            showClose: false,
            button: {
              left: {
                title: 'Abbrechen',
                class: 'btn-default',
                destroy: true,
                callback: () => {
                  mailHistoryButton.disabled = false;
                  return false;
                }
              },
              right: {
                title: 'Senden',
                class: 'btn-primary',
                destroy: true,
                callback: () => {
                  /** @type {HTMLFormElement} */
                  const formEl = document.querySelector('#email-history-form');
                  const errors = formEl.querySelector('.errors');
                  // clear previous errors
                  errors.innerHTML = '';

                  const form = new FormData(formEl);

                  const email = form.get('email').toString();
                  const comment = form.get('comment').toString();

                  if (!validateEmail(email)) {
                    errors.innerHTML = 'Bitte geben Sie eine gültige E-Mail Adresse ein.';
                    return true;
                  }

                  emailChatHistory({
                    client: currentChat,
                    discussionId: currentDiscussionId,
                    targetMail: email,
                    comment: comment || undefined
                  })
                    .then((response) => {
                      mailHistoryButton.disabled = false;
                      if (response && response.success) {
                        mailHistoryButton.classList.add('success');
                        setTimeout(() => {
                          mailHistoryButton.classList.remove('success');
                        }, 2000);
                      } else {
                        console.error(response);
                      }
                    })
                    .catch((e) => {
                      mailHistoryButton.disabled = false;
                      console.error(e);
                    });

                  return false;
                }
              }
            }
          }
        });

        modal.show();
      });

      var selectAdminEvent = function() {
        $('.chat-tabs-right button').on('click', function() {
          var objInputEl = $('.chatForm input[name="message"]');
          var objSubmitBtn = $('.chatForm .submitButton');
          objInputEl.removeAttr('disabled');
          objSubmitBtn.removeAttr('disabled');
          var objMessagesDiv = $('.direct-chat-messages');
          objMessagesDiv.html('');
          strSendToName = $(this).data('nr');
          selectChatPartner($(this).data('sid'));
          var btn = $('.chat-tabs-right').find("[data-sid='" + currentChat + "']");

          btn.removeClass('btn-info');
          btn.addClass('btn-default');
          showMessages(currentChat, objMessages, true, myGuestNumber, strSendToName);

          if (typeof objUnreadMessages[currentChat] === 'undefined') {
            objUnreadMessages[currentChat] = 0;
          }

          intUnreadMessages = intUnreadMessages - objUnreadMessages[currentChat];
          objUnreadMessages[currentChat] = 0;

          intUnreadMessages = updateTitle({
            strTitle: originalPageTitle,
            intConnectedUsers: intConnectedUsers,
            intUnreadMessages: intUnreadMessages
          });

          adjustAdminToolsVisibility();
          $('#select-admin').hide();
        });
      };

      /**
       * @param {User} user
       */
      var onUserClick = function(user) {
        var objInputEl = $('.chatForm input[name="message"]');
        var objSubmitBtn = $('.chatForm .submitButton');
        objInputEl.removeAttr('disabled');
        objSubmitBtn.removeAttr('disabled');
        const objMessagesDiv = $('.direct-chat-messages');
        objMessagesDiv.html('');
        strSendToName = String(user.number);
        selectChatPartner(user.id);

        const chatPartnerOfSelectedUser = objUserTalkingTo[currentChat];
        if (
          chatPartnerOfSelectedUser === undefined ||
          chatPartnerOfSelectedUser === strMyId ||
          chatPartnerOfSelectedUser === ''
        ) {
          const user = arrUsers.find(function(aUser) {
            return aUser.id === currentChat;
          });

          if (user !== undefined) {
            user.unread = false;
          }

          updateUsers();
          updatePartners();

          showMessages(currentChat, objMessages, true, myGuestNumber, strSendToName);

          // we don't actually care who this is,
          // as long as we found an admin (not ourself) who is available
          const nonSelfAvailableAdmin = arrAdmins.find(function(admin) {
            return admin.id !== strMyId && admin.available === AVAILABILITY.AVAILABLE;
          });

          if (nonSelfAvailableAdmin !== undefined && !isAdmin(strSendToName)) {
            $('#select-admin').show();
          } else {
            $('#select-admin').hide();
          }

          if (typeof objUnreadMessages[currentChat] === 'undefined') {
            objUnreadMessages[currentChat] = 0;
          }

          intUnreadMessages = intUnreadMessages - objUnreadMessages[currentChat];
          objUnreadMessages[currentChat] = 0;

          intUnreadMessages = updateTitle({
            strTitle: originalPageTitle,
            intConnectedUsers: intConnectedUsers,
            intUnreadMessages: intUnreadMessages
          });
        } else {
          updateUsers();
          updatePartners();

          // TODO: make this beautiful!
          alert('Die Zielperson befindet sich momentan in einem anderen Chat-Gespräch.');
          currentChat = '';
        }
        adjustAdminToolsVisibility();
      };

      // Requesting server to join chat
      socket.emit('join', session);

      socket.on('new-chat-request', function({ discussion, messages, userNumber }) {
        showNotification({
          windowFocus: windowFocus,
          userName: `Gast#${userNumber}`
        });

        if (window.isElectron) {
          window.ipcRenderer.send('show-notification');
        }

        updateTitle({
          strTitle: 'Neue Chatanfrage',
          intConnectedUsers: intConnectedUsers,
          intUnreadMessages: intUnreadMessages
        });

        requestAvailability({
          discussion: discussion,
          userName: `Gast#${userNumber}`,
          messages: messages
        })
          .then(function(accept) {
            socket.emit('new-chat-request-response', {
              session: session,
              discussion: discussion,
              response: accept
            });

            // when the admin has accepted the request,
            // mark the accepted users message as "unread"
            if (accept) {
              const user = arrUsers.find(function(aUser) {
                return aUser.number === userNumber;
              });

              if (user !== undefined) {
                user.unread = true;
              }
            }

            // realistically, we only have to update the user list
            // when the admin accepted, but it can't hurt to always do it
            updateUsers();
            updatePartners();
          })
          .catch(function(err) {
            console.error(err);
            socket.emit('new-chat-request-response', {
              session: session,
              discussion: discussion,
              response: false
            });
          });
      });

      socket.on('new-chat-request-expired', function({ discussion }) {
        destroyAvailability({ discussion });
      });

      socket.on('new-chat-request-new-message', function({ discussion, messages }) {
        updateAvailability({ discussion, messages });
      });

      // Receiving client info from server
      socket.on('yourData', function(data) {
        strMyId = data.id;
        myGuestNumber = data.number;
        strShownName = data.shownName;
        availability = data.availability;

        const { render } = habitat(StatusSelect);
        render({
          selector: '.status-select',
          defaultProps: {
            onChange: onStatusChange,
            startValue: data.availability,
            values: [
              {
                text: 'Beschäftigt',
                className: 'status status--busy',
                value: AVAILABILITY.BUSY
              },
              {
                text: 'Verfügbar',
                className: 'status status--available',
                value: AVAILABILITY.AVAILABLE
              }
            ]
          }
        });

        updateUsers();
        updatePartners();
      });

      // Update list of connected users
      socket.on('updateUser', function(users) {
        arrUsers = users.map(function(aNewUser) {
          const oldUser = arrUsers.find(function(anOldUser) {
            return anOldUser.session === aNewUser.session;
          });

          if (oldUser === undefined) {
            return { ...aNewUser, unread: false };
          }

          return { ...aNewUser, unread: oldUser.unread };
        });

        intConnectedUsers = users.length;
        intUnreadMessages = updateTitle({
          strTitle: originalPageTitle,
          intConnectedUsers: intConnectedUsers,
          intUnreadMessages: intUnreadMessages
        });

        updateUsers();
        updatePartners();

        // update favicon and do beep in case of missed messages
        changeFavicon(intUnreadMessages, '/Img/favicon_hint.ico');
        //doBeep(intUnreadMessages);
      });

      // When user refreshes the page or reconnects to socket.io
      socket.on('updateID', function(oldId, newId, oldNr, newNr) {
        // If admin is currently talking to user that reconnected
        if (currentChat === oldId) {
          selectChatPartner(newId);
        }
        // Update local messages object to new socketID
        objMessages[newId] = objMessages[oldId];
        delete objMessages[oldId];

        // Update local talkingTo object to new SocketID
        objUserTalkingTo[newId] = objUserTalkingTo[oldId];
        delete objUserTalkingTo[oldId];

        // Update local objUnreadMessages to new SocketID
        if (typeof objUnreadMessages[oldId] !== 'undefined') {
          objUnreadMessages[newId] = objUnreadMessages[oldId];
          delete objUnreadMessages[oldId];
        }
      });

      /**
       * When admin connects, receive currentlyTalkingTo info
       * @param {any} data
       */
      function lockUpdateHandler(data) {
        objUserTalkingTo = data;

        updateUsers();
        updatePartners();

        adjustAdminToolsVisibility();
      }

      /**
       * When user gets locked to admin
       * @param {string} userSessionId
       * @param {string} adminSessionId
       */
      function onUserLock(userSessionId, adminSessionId) {
        // console.log(userSessionId, 'is locked to', adminSessionId);
        objUserTalkingTo[userSessionId] = adminSessionId;

        const user = arrUsers.find(function(aUser) {
          return aUser.id === userSessionId;
        });

        user.unread = false;

        updateUsers();
        updatePartners();

        adjustAdminToolsVisibility();
      }

      /**
       * @param {any} data
       */
      function userLockedErrorHandler(data) {
        alert('Der User ist mittlerweile von einem anderen Admin kontaktiert worden.');
        console.log(data);

        objUserTalkingTo = data;

        updateUsers();
        updatePartners();
      }

      /**
       * Update list of connected admins
       * @param {Array<any>} admins
       */
      function adminsChangeHandler(admins) {
        arrAdmins = admins;
        updateAdminList({
          admins: admins,
          selfSocketId: strMyId,
          partnerSocketId: currentChat,
          partnerName: currentChat,
          callback: selectAdminEvent
        });
      }

      // Client sends message
      $('.chatForm').off('submit');
      $(document).on('submit', '.chatForm', function(e) {
        e.preventDefault();
        e.stopPropagation();
        //console.log('admin mesasge?');
        if (
          objUserTalkingTo[currentChat] === undefined ||
          objUserTalkingTo[currentChat] === strMyId ||
          objUserTalkingTo[currentChat] === ''
        ) {
          /** @type {HTMLInputElement} */
          const inputEl = document.querySelector('.chatForm input[name="message"]');

          /** @type {HTMLInputElement} */
          const fileEl = document.querySelector('.chatForm input[name="file"]');

          /** @type {string} */
          const messageRaw = inputEl.value.toString();
          var message = emoji.replace_emoticons(messageRaw);

          document.querySelector('#typing').innerHTML = '';

          var file = fileEl.files[0];
          if (file === undefined) {
            sendMessage(
              {
                from: strMyId,
                fromName: myGuestNumber,
                to: currentChat,
                message: message
              },
              socket
            );
            inputEl.value = '';
            return;
          }

          var submitButton = document.querySelector('.chatForm .submitButton');
          submitButton.setAttribute('disabled', 'disabled');

          uploadFile(file, function({ filename, error }) {
            if (!error) {
              sendMessage(
                {
                  from: strMyId,
                  fromName: myGuestNumber,
                  to: currentChat,
                  message: message,
                  file: filename
                },
                socket
              );
              inputEl.value = '';
              fileEl.value = null;
              $('label[for="fileupload"]').removeClass('active');
              $('label[for="fileupload"] span.filename').html('Datei auswählen...');
            } else {
              document.querySelector('#typing').innerHTML =
                '<span style="color: #ff0000">Dateiformat nicht erlaubt!</span>';
            }
            submitButton.removeAttribute('disabled');
          });
        } else {
          // TODO: make this beautiful!
          alert('Die Zielperson befindet sich momentan in einem anderen Chat-Gespräch.');
        }
        return false;
      });

      // When chat history is handed over to another admin
      $(document).on('click', '#action-hand-over', function(e) {
        e.preventDefault();
        e.stopPropagation();

        const adminSocketId = $('#select-admin select')
          .find(':selected')
          .data('sid');

        // find the corresponding admin object
        const admin = arrAdmins.find(function(admin) {
          return admin.id === adminSocketId;
        });

        if (admin === undefined) {
          return alert(
            'Es ist ein Fehler aufgetreten. Dieser Administrator konnte nicht ausgewählt werden.'
          );
        }

        if (admin.available !== AVAILABILITY.AVAILABLE) {
          return alert('Dieser Administrator ist momentan nicht verfügbar.');
        }

        // only hand over when messages exist
        if (typeof objMessages[currentChat] !== 'undefined') {
          socket.emit(
            'chatHistoryHandOver',
            session,
            adminSocketId,
            currentChat,
            objMessages[currentChat].name
          );

          // Reset local array because user was handed over to someone else
          // TODO: find a better way to do this
          objUserTalkingTo[currentChat] = adminSocketId;
          // Reset current chat
          unselectChatPartner();
        }
      });

      // On keypress tell server that user started typing
      // If user stops typing, after a brief delay, tell server
      $(document).on('keypress', '.chatForm input', function(e) {
        if (e.which == 13) {
          timeoutFunction();
        } else {
          if (
            typing === false &&
            currentChat !== '' &&
            $('.chatForm')
              .find('input[name="message"]')
              .is(':focus')
          ) {
            typing = true;
            socket.emit('typing', {
              isTyping: true,
              strSendToId: currentChat,
              strName: strShownName,
              session: session
            });
          } else {
            clearTimeout(timeout);
          }
          timeout = setTimeout(timeoutFunction, 3000);
        }
      });

      /**
       * When admin receives chat history from another admin
       * @param {{ userId: string, data: MessagesDictionary[0] }} data
       */
      function receiveChatHandler(data) {
        showNotification({
          windowFocus: windowFocus,
          userName: `Gast#${data.data.name}`
        });

        const user = arrUsers.find(function(aUser) {
          return aUser.id === data.userId;
        });

        if (user !== undefined) {
          user.unread = true;
        }

        updateUsers();
        updatePartners();

        updateTitle({
          strTitle: 'Neue Chatanfrage',
          intConnectedUsers: intConnectedUsers,
          intUnreadMessages: intUnreadMessages
        });

        objMessages[data.userId] = data.data;
        $('.modal').show();
        $('.modal .modal-title').text('Chat-Verlauf wurde übergeben');
        $('.modal .modal-body p').text(
          'Gast#' + data.data.name + ' wurde Ihnen zugeteilt.'
        );
        $('button[data-dismiss="modal"]').hide();
        $('.modal-footer .btn-primary').text('Verstanden');
        $('.modal-footer .btn-primary').on('click', function() {
          $('.modal').hide();
        });
        objUserTalkingTo[data.userId] = strMyId;
      }

      // 'User is typing' functionality
      function timeoutFunction() {
        typing = false;
        socket.emit('typing', {
          isTyping: false,
          strSendToId: currentChat,
          strName: strShownName,
          session: session
        });
      }

      /**
       * When user starts typing, show notification
       * @param {Object} params
       * @param {boolean} params.isTyping
       * @param {string} params.id
       * @param {string} params.person
       */
      function typingHandler({ isTyping, id, person }) {
        if (isTyping && id === currentChat) {
          $('#typing').text('Gast#' + person + ' schreibt...');
        } else {
          $('#typing').text('');
        }
      }

      /**
       * Client receives message
       * @param {Object} messageData
       * @param {string} messageData.from
       * @param {string} messageData.fromName
       * @param {string} messageData.shownName
       * @param {string} messageData.to
       * @param {string} messageData.time
       * @param {string} messageData.message
       * @param {{filename?: string}} messageData.file
       * @param {string} [messageData.fromAvatar]
       */
      function newMessageHandler(messageData) {
        let author = messageData.from;
        let strFromName = messageData.fromName;
        let shownName = messageData.shownName;
        let strTo = messageData.to;
        let date = new Date(messageData.time);
        let message = messageData.message;
        let file = messageData.file;
        const avatar = messageData.fromAvatar;

        if (file.filename !== undefined) {
          message = appendFileToMessage(file, message);
        }

        // SAVE MESSAGE LOCALLY

        // If message is mine
        if (author === strMyId) {
          saveMessage({
            target: strTo,
            messages: objMessages,
            message: message,
            date: date,
            self: true,
            sendToName: strSendToName,
            shownName: shownName,
            sendFromName: strFromName,
            avatar: avatar
          });
          adjustAdminToolsVisibility();
          showMessages(strTo, objMessages, true, myGuestNumber, strSendToName);
          // If message isn't mine
        } else {
          saveMessage({
            target: author,
            messages: objMessages,
            message: message,
            date: date,
            self: false,
            sendToName: strFromName,
            shownName: shownName,
            sendFromName: strFromName,
            avatar: avatar
          });

          // If Chat Tab is active, load messages. Otherwise, create notifications
          if (currentChat === author) {
            showMessages(author, objMessages, true, myGuestNumber, strFromName);
          } else {
            const user = arrUsers.find(function(aUser) {
              return aUser.id === author;
            });

            if (user !== undefined) {
              user.unread = true;
            }

            updateUsers();
            updatePartners();
          }

          if (!windowFocus || currentChat !== author) {
            intUnreadMessages++;

            if (typeof objUnreadMessages[author] !== 'undefined') {
              objUnreadMessages[author]++;
            } else {
              objUnreadMessages[author] = 1;
            }

            intUnreadMessages = updateTitle({
              strTitle: originalPageTitle,
              intConnectedUsers: intConnectedUsers,
              intUnreadMessages: intUnreadMessages
            });

            if (window.isElectron) {
              window.ipcRenderer.send('show-notification');
            }

            // update favicon and do beep in case of missed messages
            changeFavicon(intUnreadMessages, '/Img/favicon_hint.ico');
            doBeep(intUnreadMessages);
          }

          // generate notification if window is not in focus
          if (
            Notification &&
            Notification.permission === 'granted' &&
            !windowFocus &&
            typeof message !== 'undefined'
          ) {
            const notification = new Notification('Neue Chat Nachricht', {
              //originally it was strFromName
              body:
                (isAdmin(strFromName) ? '' + strShownName : 'Gast#' + strFromName) +
                ': ' +
                message,
              icon:
                avatar !== undefined
                  ? `/avatars/${avatar}`
                  : 'Img/avatar6_deskt_notif.png'
            });

            if (window.isElectron) {
              window.ipcRenderer.send('show-notification');
            }

            // when clicked on notification bring original window to focus
            notification.onclick = function() {
              window.focus();
              notification.close();
              let user = arrUsers.find(function(aUser) {
                return aUser.id === author;
              });

              if (user === undefined) {
                user = arrAdmins.find(function(anAdmin) {
                  return anAdmin.id === author;
                });
              }

              if (user !== undefined) {
                user.unread = false;

                const objInputEl = $('.chatForm input[name="message"]');
                const objSubmitBtn = $('.chatForm .submitButton');
                objInputEl.removeAttr('disabled');
                objSubmitBtn.removeAttr('disabled');
              }

              updateUsers();
              updatePartners();

              intUnreadMessages = 0;
              intUnreadMessages = updateTitle({
                strTitle: originalPageTitle,
                intConnectedUsers: intConnectedUsers,
                intUnreadMessages: intUnreadMessages
              });

              // update favicon and do beep in case of missed messages
              changeFavicon(intUnreadMessages, '/Img/favicon_hint.ico');
              doBeep(intUnreadMessages);

              selectChatPartner(author);
              showMessages(author, objMessages, true, myGuestNumber, strFromName);
            };

            // hide notification after 10 Seconds
            setTimeout(function() {
              notification.close();
            }, 10000);
          }
        }

        // always scroll new message to view
        $('.direct-chat-messages').scrollTop($('.direct-chat-messages')[0].scrollHeight);
      }

      /**
       * @param {Object} params
       * @param {string} params.sessionId
       * @param {string} params.discussionId
       */
      function onSessionDisconnectHandler({ sessionId, discussionId }) {
        console.log(`Session ${sessionId} disconnected.`);

        if (currentChat === sessionId) {
          disableForm();

          document.querySelector('.back').classList.add('inactive-chat-partner');

          currentDiscussionId = discussionId;
        }
      }

      function onNewVideoChatRequestHandler({ userName, userSession }) {
        requestVideoChat({
          userName: userName
        })
          .then(function(accept) {
            if (accept) {
              sendMessage(
                {
                  from: strMyId,
                  fromName: myGuestNumber,
                  to: currentChat,
                  message: `Ihr Ansprechpartner hat ihre Video-Anfrage akzeptiert und tritt nun bei. `
                },
                socket
              );
              sendMessage(
                {
                  from: strMyId,
                  fromName: myGuestNumber,
                  to: currentChat,
                  message: `Der Video-Chat wird sich in einem neuem Fenster öffnen. Sollten Sie das Fenster ausversehen schließen oder es sich nicht öffnen, können Sie den Video-Chat hier erneut öffnen: https://jitsi.ws-chat.de/${session}--${userSession}`
                },
                socket
              );
              console.log({ session });
              console.log({ userSession });
              socket.emit('new-video-chat-request:accept', { session, userSession });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }

      function onVideoChatStartHandler({ id }) {
        getNewVideo(id, io);
      }

      socket.on('lockUserInfo', lockUpdateHandler);
      socket.on('lockUser', onUserLock);
      socket.on('error:user-alreay-locked', userLockedErrorHandler);
      socket.on('updateAdmins', adminsChangeHandler);
      socket.on('sendMessage', newMessageHandler);
      socket.on('chatHistoryHandOverTarget', receiveChatHandler);
      socket.on('isTyping', typingHandler);
      socket.on('disconnect', () => onSessionExpire(10000));
      socket.on('session:disconnect', onSessionDisconnectHandler);
      socket.on('new-video-chat-request', onNewVideoChatRequestHandler);
      socket.on('video-chat:start', onVideoChatStartHandler);

      // get Notification API from browser if not is already granted
      if ('Notification' in window && Notification.permission !== 'granted') {
        Notification.requestPermission();
      }

      // set windowFocus on change
      $(window)
        .focus(function() {
          windowFocus = true;

          if (currentChat !== '') {
            if (typeof objUnreadMessages[currentChat] === 'undefined') {
              objUnreadMessages[currentChat] = 0;
            }

            intUnreadMessages = intUnreadMessages - objUnreadMessages[currentChat];
            objUnreadMessages[currentChat] = 0;
            intUnreadMessages = updateTitle({
              strTitle: originalPageTitle,
              intConnectedUsers: intConnectedUsers,
              intUnreadMessages: intUnreadMessages
            });

            // update favicon and do beep in case of missed messages
            changeFavicon(intUnreadMessages, '/Img/favicon_hint.ico');
            // doBeep(intUnreadMessages);
          }
        })
        .blur(function() {
          windowFocus = false;
        });

      const videoChatWrapper = document.querySelector('.videoChatWrapper');
      if (!SUPPORTS_JITSI) {
        videoChatWrapper.classList.add('disabled');

        tippy(videoChatWrapper, {
          content:
            'Ihr Browser unterstützt diese Funktion nicht. Nutzen Sie bitte einen modernen Browser wie Chrome oder Firefox.'
          // to debug, use these props:
          // hideOnClick: false,
          // trigger: 'click'
        });
      }
    };

    /**
     * Warning message when admin is trying to reload/leave page
     */
    function beforeUnload(e) {
      var userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf(' electron/') < 0) {
        var confirmationMessage = 'Chat-Verläufe gehen verloren, wenn Sie neu laden';

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
      }
    }

    function onSessionExpire(errorCode) {
      if (sessionExpired) {
        return;
      }
      sessionExpired = true;
      sessionExpiredModal = new Modal({
        title: `Session abgelaufen (Error ${errorCode})`,
        content: `Ihre Session ist leider abgelaufen. Loggen Sie sich bitte erneut ein.`,
        options: {
          showClose: false,
          button: {
            right: {
              title: 'Zum Login',
              class: 'btn-primary',
              destroy: true,
              callback: () => {
                window.removeEventListener('beforeunload', beforeUnload);
                window.location.href = '/admin/logout';
                return false;
              }
            }
          }
        }
      });

      sessionExpiredModal.show();
    }

    /**
     * When login form is loaded
     */
    var loginButtonAction = function() {
      /** @type {HTMLInputElement} */
      const emailField = document.querySelector('#email');
      /** @type {HTMLInputElement} */
      const passwordField = document.querySelector('#password');

      const emailFromCookie = cookies.get('email');

      if (emailFromCookie) {
        emailField.value = emailFromCookie;
      }

      $('#customForm').off('submit');
      $('#customForm').on('submit', function(e) {
        const email = emailField.value;
        const pass = passwordField.value;

        cookies.set('email', email, {
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30)
        });

        if (typeof request !== 'undefined') request.abort();

        var request = $.ajax({
          method: 'POST',
          url: '/admin/login',
          data: {
            email: email,
            pass: pass,
            session: false
          },
          success: function(result) {
            if (result.loginStatus === '1') {
              getContent(chat, back, function() {
                onLogin(result.session);
              });
              $('.divider').show();
              $('.usermenu').show();
              $('.logout').show();
              $('.export').show();
              $('.archive').show();
              $('#card').addClass('flipped');
              $('#customLoginBox').fadeOut('fast');
            } else if (result.loginStatus == '2') {
              $('#customError').html(
                '<div class="callout callout-danger">Session existiert bereits als User.</div>'
              );
            } else if (result.loginStatus == '3') {
              // when session was destroyed and user is trying to log in without a session
              $('#customError').html(
                '<div class="callout callout-danger">Session Error. Diese Seite bitte neu laden.</div>'
              );
            } else {
              $('#customError').html(
                '<div class="callout callout-danger">Login ist fehlgeschlagen.</div>'
              );
            }
          }
        });
        e.preventDefault();
      });

      $('.ripple').on('click', function(event) {
        var $div = $('<div/>'),
          btnOffset = $(this).offset(),
          xPos = event.pageX - btnOffset.left,
          yPos = event.pageY - btnOffset.top;

        $div.addClass('ripple-effect');
        var $ripple = $('.ripple-effect');

        $ripple.css('height', $(this).height());
        $ripple.css('width', $(this).height());

        $div
          .css({
            top: yPos - $ripple.height() / 2,
            left: xPos - $ripple.width() / 2,
            background: $(this).data('ripple-color')
          })
          .appendTo($(this));

        window.setTimeout(function() {
          $div.remove();
        }, 2000);
      });
    };

    $.ajax({
      url: '/admin/remember',
      type: 'get',
      success: function(result) {
        if (result.loginStatus === '1') {
          getContent(chat, front, function() {
            onLogin(result.session);
          });
          $('.divider').show();
          $('.usermenu').show();
          $('.divider').show();
          $('.logout').show();
          $('.export').show();
          $('.archive').show();
          // $('#card').addClass('flipped');
          // $('#customLoginBox').fadeOut('fast');
        } else {
          getContent(login, front, loginButtonAction);
        }
      }
    });

    // Loader effect
    $(document).on({
      ajaxStart: function() {
        $('.login-box .overlay').removeClass('hidden');
      },
      ajaxStop: function() {
        $('.login-box .overlay').addClass('hidden');
        uploadFileButtonFunc();
      }
    });
  });
})(jQuery);
