// @ts-check

class Modal {
  /**
   * @param {{title: string, content: string, options?: {showClose: boolean, button: {[key: string]: { callback?: () => boolean, title: string, destroy: boolean, class: string }}}}} options
   */
  constructor({ title, content, options = { button: {}, showClose: true } }) {
    this.title = title;
    this.content = content;
    this.options = options;

    this.open = false;

    this.buildDom();
  }

  getModal() {
    return this.modal;
  }

  show() {
    this.open = true;

    jQuery(this.modal).modal('show');
  }

  hide() {
    this.open = false;

    jQuery(this.modal).modal('hide');
  }

  toggle() {
    if (this.open) {
      this.hide();
    } else {
      this.show();
    }
  }

  update({ title, content }) {
    if (title) {
      this.title = title;
    }

    if (content) {
      this.content = content;
    }

    this.modal.querySelector('.modal-body').innerHTML = this.content;
    this.modal.querySelector('.modal-title').innerHTML = this.title;
  }

  destroy() {
    jQuery(this.modal).on('hidden.bs.modal', () => {
      this.modal.remove();
    });

    this.hide();
  }

  leftClick() {
    if (typeof this.options.button.left.callback === 'function') {
      const shouldStay = this.options.button.left.callback();
      if (shouldStay) {
        return;
      }
    }

    if (this.options.button.left.destroy) {
      this.destroy();
    } else {
      this.hide();
    }
  }

  rightClick() {
    if (typeof this.options.button.right.callback === 'function') {
      const shouldStay = this.options.button.right.callback();
      if (shouldStay) {
        return;
      }
    }

    if (this.options.button.right.destroy) {
      this.destroy();
    } else {
      this.hide();
    }
  }

  appendClickEvents() {
    if (this.options.button.left) {
      const leftButton = this.modal.querySelector('.modal-footer .button-left');

      leftButton.addEventListener('click', (e) => {
        e.preventDefault();

        this.leftClick();
      });
    }

    if (this.options.button.right) {
      const rightButton = this.modal.querySelector('.modal-footer .button-right');

      rightButton.addEventListener('click', (e) => {
        e.preventDefault();

        this.rightClick();
      });
    }
  }

  buildDom() {
    const wrapper = document.createElement('div');

    wrapper.innerHTML = `
      <div class="modal modal-${Math.round(1000 * Math.random())}">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              ${
                this.options.showClose
                  ? `<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button
              >`
                  : ''
              }
              <h4 class="modal-title">${this.title}</h4>
            </div>
            <div class="modal-body">
              ${this.content}
            </div>
            <div class="modal-footer">
            ${
              this.options.button.left
                ? `<button class="btn ${this.options.button.left.class} pull-left close-bottom-left button-left">${this.options.button.left.title}</button>`
                : ''
            }
            ${
              this.options.button.right
                ? `<button class="btn ${this.options.button.right.class} button-right">${this.options.button.right.title}</button>`
                : ''
            }
            </div>
          </div>
        </div>
      </div>
    `;

    this.modal = wrapper.querySelector('.modal');
    this.appendClickEvents();

    document.body.appendChild(this.modal);
  }
}

export { Modal };
