import { h, Fragment } from 'preact';

const deviceNames = {
  L: 'Desktop',
  M: 'Tablet',
  S: 'Smartphone'
};

export const PageInfo = ({ device, url, title, tabs }) => {
  let deviceInfo = null;

  if (['L', 'M', 'S'].includes(device)) {
    deviceInfo = (
      <span title={`${deviceNames[device]} Besucher`}>
        <b>({device})</b>
      </span>
    );
  }


  return (
    <Fragment>
      {deviceInfo}{' '}
      <span title={`Besucher hat ${tabs} Tab${tabs > 1 ? 's' : ''} geöffnet`}><b>({tabs} T)</b></span> auf{' '}
      <a href={url} title={url} target="_blank">
        {title}
      </a>
    </Fragment>
  );
};
