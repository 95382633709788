// @ts-check

/**
 * @param {{ client?: string, discussionId?: string, targetMail: string, comment?: string }} options
 * @return {Promise<any>}
 */
export function emailChatHistory({ client, discussionId, targetMail, comment }) {
  return fetch('/admin/mail-history', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ client: client, targetMail, comment, discussionId })
  }).then((res) => res.json());
}
